import React from 'react';
import './AboutContent.css';

function AboutContent(props) {
    const resume = 'https://www.kadewebsolutions.com/CKade_CV_compressed.pdf';
    const personal = 'https://www.kadewebsolutions.com/personal.JPEG'
    
    if (props.width < 768){
        return (
            mobileAbout()
        )
    } else if(props.width >= 768 && props.width <= 1023){
        return (
            tabletAbout()
        )
    }else if (props.width >= 1024){
        return (
            desktopAbout()
        )
    }
    function mobileAbout(){
        return (
            <main className='h-screen mb-40'>
                <section>
                    <h5 id='front-head' className='text-center mt-8 text-green-200 text-4xl font-bold'>About the Developer</h5>
                    <div className='flex justify-center mt-8 w-1/2 mr-auto ml-auto headshot'><img src={personal} alt="Chris KadeHeadshot" /></div>
                    <p className='w-2/3 ml-auto mr-auto mt-8 text-center text-white font-mono text-sm'>My name is Chris Kade and I am from Lake Orion, Michigan. I have an associate's degree in Computer Information Systems from Oakland Community College. I have been developing websites for over 2 years.
                        Although the majority of my work is in the field of web development, I also use Python and Java frequently. I am always open to new opportunities to grow.
                    </p>
                    <div className='w-full flex justify-center mt-10'>
                        <a className='text-2xl font-mono bg-green-200 hover:text-green-200 text-center hover:bg-gray-500 p-3 rounded-md ' href={resume} download='chris-kade-resume.pdf' target='_blank'>Download my resume</a>
                    </div>
                </section>
            </main>
        )
    }
    function tabletAbout(){
        return (
            <main className='mb-10'>
                <section>
                    <h5 id='front-head' className='text-center mt-8 text-green-200 text-4xl font-bold'>About the Developer</h5>
                    <div className='flex justify-center mt-8 w-1/2 mr-auto ml-auto headshot-dsk'><img src={personal} alt="Chris Kade Headshot" /></div>
                    <p className='w-2/3 ml-auto mr-auto mt-8 text-center text-white font-mono text-md'>My name is Chris Kade and I am from Lake Orion, Michigan. I have an associate's degree in Computer Information Systems from Oakland Community College. I have been developing websites for over 2 years.
                        Although the majority of my work is in the field of web development, I also use Python and Java frequently. I am always open to new opportunities to grow.
                    </p>
                    <div className='w-full flex justify-center mt-10 mb-10'>
                        <a className='text-2xl font-mono bg-green-200 hover:text-green-200 text-center hover:bg-gray-500 p-3 rounded-md ' href={resume} download='chris-kade-resume.pdf' target='_blank'>Download my resume</a>
                    </div>
                </section>
            </main>
        )
    }
    function desktopAbout(){
        return (
            <main className='mb-24 w-full'>
                <section className='w-full'>
                    <h5 id='front-head' className='text-center mt-8 text-green-200 text-4xl font-bold'>About the Developer</h5>
                    <div className='flex justify-center mt-8 mr-auto ml-auto headshot-dsk'>
                        <img src={personal} alt="Chris KadeHeadshot" />
                    </div>
                    <p className='w-2/3 ml-auto mr-auto mt-12 text-center text-white font-mono text-lg mb-10'>My name is Chris Kade and I am from Lake Orion, Michigan. I have an associate's degree in Computer Information Systems from Oakland Community College. I have been developing websites for over 2 years.
                        Although the majority of my work is in the field of web development, I also use Python and Java frequently. I am always open to new opportunities to grow.
                    </p>
                    <div className='w-full flex justify-center'>
                        <a className='text-2xl font-mono bg-green-200 hover:text-green-200 text-center hover:bg-gray-500 p-3 rounded-md ' 
                        href={resume}
                        download='chris-kade-resume.pdf'
                        target='_blank'>Download my resume</a>
                    </div>
                    {/**   */}
                </section>
            </main>
        )
    }
}

export default AboutContent;
